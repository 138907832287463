import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import ReactGA from "react-ga";

const Head = ({ title, image, description, keywords, author }) => {
  const [cookiesConsent, setCookiesConsent] = useState('');
  useEffect(() => {
    setCookiesConsent(localStorage.getItem('cookiesConsent'));
  }, []);

  const isEU = window.cnxEU;
  const metaData = [
    { property: 'og:title', content: title ? title : "Connatix" },
    { property: 'og:type', content: "website" },
    { property: 'og:image', content: image ? image : "https://connatix.com/assets/share-picture.png" },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    { name: 'author', content: author },
  ];

  if (!isEU || cookiesConsent === 'true') {
    // Google Analytics
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <Helmet
        title={title ? title : "Connatix"}
        meta={metaData}
        link={[{ rel: 'canonical', href: window.location }]}
        script={[
          { // Munchkin Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/munchkin-script.js`
          },
          { // UTM Script (MKT CKE)
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/mkt-cke-script.js`
          },
          { // Facebook Pixel Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/facebook-pixel-script.js`
          },
          { // LinkedIn Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/linkedin-script.js`
          },
          { // GAConnector - version from May, 20, 2020
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/gaconnector.js`,
          },
          { // Microsoft Ad Tracking Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/microsoft-ad-script.js`,
          },
          { // Hotjar Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/hotjar.js`,
          },
          { // WebSights
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/websights-script.js`,
          }
        ]}
      >
      </Helmet>
    )
  } else {
    return (
      <Helmet
        title={title ? title : "Connatix"}
        meta={metaData}
        link={[{ rel: 'canonical', href: window.location }]}
        script={[
          { // Hotjar Script
            type: 'text/javascript',
            src: `${process.env.PUBLIC_URL}/scripts/hotjar.js`,
          }
        ]}
      >
      </Helmet>
    )
  }
};

Head.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  author: PropTypes.string,
};

export default Head;