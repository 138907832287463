import React, { useState, useEffect } from 'react';
import axios from "axios";

import ContactFormSection from "../../../components/ContactFormSection/ContactFormSection";
import TwoColumnsTextSection from "../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";
import TwoRowsTextSection from "../../../hoc/TwoRowsTextSection/TwoRowsTextSection";

import section1Image from '../../../assets/playspace/playspace_section1.png';
import section2Image from '../../../assets/playspace/playspace_section2.png';
import section3Image from '../../../assets/playspace/playspace_section3.png';
import section4Image from '../../../assets/playspace/playspace_section4.png';
import section4ImageMobile from '../../../assets/playspace/playspace_section4_mobile.png';

import formDecoration from '../../../assets/contact/contact-form-decoration-purple.png';

import Head from "../../../layout/Head/Head";
import PlayspaceHeader from "./components/PlayspaceHeader/PlayspaceHeader";
import Carousel from '../../../hoc/Carousel/Carousel';
import QuotesCarousel from '../../../components/QuotesCarousel/QuotesCarousel';

import * as styles from './index.module.scss';
import section5DecorativeImage from '../../../assets/playspace/playspace_decorative_section5.png';
const section5DecorativeImageId = 'section5__decorative';
const section5DecorativeImageStyle = `
  #${section5DecorativeImageId} {
    max-width: 120px;
    min-width: 75px;
    width: 10%;
    bottom: -3%;
    left: 1%;
  }

  @media only screen and (min-width: 62rem) {
    #${section5DecorativeImageId} {
      width: 30%;
      bottom: -7%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section5DecorativeImageId} {
      left: 10%;
    }
  }
`;

const PlayspacePage = props => {
  const sectionOptions = {
    imageBackgroundMargin: "8% 0% 8% -4%",
    backgroundColor: "#EFF3F5",
    direction: 'row',
    decorativeImage: section5DecorativeImage,
    decorativeImageId: section5DecorativeImageId,
    decorativeImageStyle: section5DecorativeImageStyle
  };
  const [playspaceCarousel, setPlayspaceCarousel] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quotesSectionTitle, setQuotesSectionTitle] = useState();
  const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/PlayspacePageFeaturedItemsCarousel`)
      .then(response => {
        setPlayspaceCarousel(response.data.entries ? response.data.entries : [])
      });


    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/playspaceQuotesCarousel`)
      .then(response => {
        setQuotes(response.data.entries ? response.data.entries : [])
        if (response.data.entries) {
          //Set Quote Section Background Color
          let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
          setQuotesSectionBgColor(entryWBgColor.backgroundColor);
          //Set Quote Section Title
          let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
          setQuotesSectionTitle(entryWTitle.sectionTitle);
        }

      })
      .catch(error => console.log('Quotes Carousel Error: ', error));
  }, []);


  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix - Video Monetization" />

      {/* Header */}
      <PlayspaceHeader {...props} />

      <main>
        {/* Section 1 */}
        <section>
          <TwoColumnsTextSection
            image={section1Image}
            imageMargin="2% 0 0"
            imageBackgroundMargin="-2% -4% -4%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            title="Integrated Exchange"
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Tap into premium demand from the Connatix exchange"
            bulletList={["Powered by an in-house sales team", "Direct demand from top brands", "Partnerships with major agencies", "Integrations with the leading DSPs"]}
          />
          


        </section>

        {/* Section 2 */}
        <section>
          <TwoColumnsTextSection
            image={section2Image}
            imageBackgroundMargin="6% -4% -6%"
            direction="row"
            backgroundColor="#FFFFFF"
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            title="Built-in Ad Server"
            description="Empower sales teams with an ad server built for video"
            bulletList={["Deliver publisher-direct deals using Connatix's DSP integrations", "Set web, user, content, and contextual targeting rules", "Manage campaigns with viewability thresholds and priority levels", "Supported creative types include: third-party (VAST), video header bidding (Prebid.js, Amazon TAM), and more"]}
          />
        </section>

        {/* Section 3 */}
        <section>
          <TwoColumnsTextSection
            image={section3Image}
            imageMargin="2% 0 0"
            imageBackgroundMargin="-2% -4% -2%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Offer cookieless targeting with built-in contextual technology"
            bulletList={["Access proprietary multi-layer video analysis", "Enable targeting based on page or video-level contextual classification", "Empower sales teams to sell contextually targeted packages"]}
            buttonText="LEARN MORE"
            buttonLink="/deep-contextual"
          />
        </section>

        {/* Section 4 */}
        <section>
          <TwoRowsTextSection
            image={section4Image}
            imageMobile={section4ImageMobile}
            imageMargin='0 0 0'
            imageBackgroundMargin="8% 0 0"
            direction="column"
            backgroundColor="#FFFFFF"
            descriptionDecorationColor="#FFCC00"
            hasPaddingBottom={true}
            description="Increase efficiency with direct supply paths"
            info="Reduce ad tech fees and grow revenue with Connatix’s direct DSP and Prebid.js integrations"
          />
        </section>

        {/* Section 5 - Carousel */}
        <Carousel carouselItems={playspaceCarousel} sectionOptions={sectionOptions}>
          <img id={section5DecorativeImageId} className={styles['decorative__image']} src={section5DecorativeImage} alt="" />
        </Carousel>

        {/*Quotes */}
        <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
          <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
        </section>

        {/* Contact form */}
        <ContactFormSection id="playspace-contact"
          title="Amplify your revenue without increasing your workload."
          titleDecorationColor='#F2DC4F'
          description="Reach out for a personalized demo."
          formID="1066"
          formDecoration={formDecoration}
        />
      </main>
    </div>
  )
};

export default PlayspacePage;