import React, {  useState, useEffect } from 'react';
import Head from '../../../layout/Head/Head';
import axios from "axios";

import techSnapshotDecorativeImage from '../../../assets/elements/elements_decorative_section4.png';

import ContactFormSection from "../../../components/ContactFormSection/ContactFormSection";
import TwoColumnsTextSection from "../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";

import section1Image from '../../../assets/elements/elements_section1.png';
import section3Image from '../../../assets/elements/elements_section3.png';
import formDecoration from '../../../assets/contact/contact-form-decoration-purple.png';

import { TechSnapshot } from "./components/TechSnapshot/TechSnapshot";
import Experiences from "./components/Experiences/Experiences";
import ElementsHeader from './components/ElementsHeader.js/ElementsHeader';
import Carousel from '../../../hoc/Carousel/Carousel';

import styles from './index.module.scss';
import QuotesCarousel from '../../../components/QuotesCarousel/QuotesCarousel';

const ElementsPage = props => {
  const sectionOptions = {
    imageBackgroundMargin: "8% 0% 8% -4%",
    backgroundColor: "#FFFFFF",
    direction: 'row'
  }


  const [elementsCarousel, setElementsCarousel] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quotesSectionTitle, setQuotesSectionTitle] = useState();
  const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/ElementsPageFeaturedItemsCarousel`)
      .then(response => {
        setElementsCarousel(response.data.entries ? response.data.entries : []);

      });
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/ElementsQuotesCarousel`)
      .then(response => {
        setQuotes(response.data.entries ? response.data.entries : [])
        if (response.data.entries) {
          //Set Quote Section Background Color
          let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
          setQuotesSectionBgColor(entryWBgColor.backgroundColor);
          //Set Quote Section Title
          let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
          setQuotesSectionTitle(entryWTitle.sectionTitle);
        }

      })
      .catch(error => console.log('Quotes Carousel Error: ', error));
  }, []);



  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix - Video Delivery" />

      <ElementsHeader {...props} />

      <main>
      {/* Section 1 */}
  
      <section>
          <TwoColumnsTextSection
            image={section1Image}
            imageMargin="2% 0 0"
            imageBackgroundMargin="-2% -4% -4%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            title="Premium Player"
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Press play on a fast, lightweight, and reliable player"
            bulletList={["HTML5 player with a 99.99% uptime", "Flexible and uninterrupted streaming", "Render high-quality video with adaptive bitrate HD encoding and HLS streaming", "Cross-device compatibility, in-app SDKs, and AMP integration creating continuity across all screens", "No annual or recurring player fees"]}
          
          />
        </section>

        {/* Section 2 */}
        <section>
          <Experiences />
        </section>

        {/* Section 3 */}
     
        <section>
          <TwoColumnsTextSection
            image={section3Image}
            imageMargin="2% 0 0"
            imageBackgroundMargin="-2% -4% -4%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            title="Intuitive Video Library"
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Easily upload and manage content with a built-in video library"
            bulletList={["Contextual, keyword, and manual playlist settings", "Native WordPress plugin", "Automated in-video contextual analysis and classification", "Media API to seamlessly upload content", "Google Chrome extension for easy access to the Connatix platform"]}
          />
        </section>

        {/* Section 4 - Carousel */}
        <Carousel carouselItems={elementsCarousel} sectionOptions={sectionOptions}></Carousel>

        {/* Section 5 */}
        <section className={styles['techSnapshot-section']}>
          <img className={styles['techSnapshot-decorative']} src={techSnapshotDecorativeImage} alt="" />
          <TechSnapshot />
        </section>

        {/*Quotes */}

        <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
          <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
        </section> 


        {/* Contact form */}
        <ContactFormSection id="elements-contact"
          title="Are you ready to light up your online video strategy?"
          titleDecorationColor='#F2DC4F'
          description="Reach out for a personalized demo."
          formID="1067"
          formDecoration={formDecoration}
        />
      </main>
    </div>
  )
};

export default ElementsPage;