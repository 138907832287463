import React, { useEffect, useState } from 'react'
import PricingHeader from './components/PricingHeader/PricingHeader';
import PricingTable from './components/PricingTable/PricingTable';
import axios from "axios";
import Head from "../../layout/Head/Head";
import * as styles from './index.module.scss';
import LogosGallery from '../home/components/LogosGallery/LogosGallery';
import CommonQuestions from './components/CommonQuestions/CommonQuestions';
import ContactFormSection from '../../components/ContactFormSection/ContactFormSection';
import formDecoration from '../../assets/contact/contact-form-decoration-black.png';

const PricingPage = () => {

    const [tableData, setTableData] = useState([{}]);
    const [pricingOptionsData, setPricingOptions] = useState([]);
    const [isTechSass, setTechSass] = useState(false);
    const [logosGallery, setLogosGallery] = useState([]);
    const [isBottomHeaderVisible, setBottomHeaderVisibility] = useState();
    const [headerInfo, setHeaderInfo] = useState({});
    const className = 'pricing-logo-carousel';
    const toggleTechSass = (isTechSass) => {
        setTechSass(isTechSass);
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/pricingTable`)
            .then(response => {
                setTableData(response.data.entries ? response.data.entries : []);
            })
            .catch(error => console.log('error', error));


        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/pricingOptions`)
            .then(response => {
                setPricingOptions(response.data.entries ? response.data.entries : []);
            })
            .catch(error => console.log('error', error));

        axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/homepageLogos`)
            .then(response => {
                setLogosGallery(response.data.logosGallery ? response.data.logosGallery : [])
            });
        axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/pricingPageHeader`)
            .then(response => {
                setHeaderInfo(response.data ? response.data : {});
            })
            .catch(error => console.log('Pricing Header Error', error));
    }, []);


    const setHeaderVisibility = (isHeaderVisible) => {
        setBottomHeaderVisibility(isHeaderVisible);
    }


    return (
        <div className={styles['pricing']}>
            <Head title="Connatix - Pricing"/>
            <PricingHeader setHeaderVisibility={setHeaderVisibility} toggleTechSass={toggleTechSass} isTS={isTechSass} />

            <PricingTable headerInfo={headerInfo} isBottomHeaderVisible={isBottomHeaderVisible} tableData={tableData} isTS={isTechSass} pricingOptionsData={pricingOptionsData} />

            <div className={styles['logos-wrapper']}>
                <LogosGallery logosGallery={logosGallery} logosTitle="Trusted by over 340 top publishers" className={className} />
            </div>

            <CommonQuestions />
            <ContactFormSection id="pricing-contact"
                title="Ready to light up your online video strategy?"
                titleDecorationColor='#FFCC00'
                description="Reach out to get started."
                formID="1497"
                formDecoration={formDecoration}

            />
        </div>
    )
};

export default PricingPage;