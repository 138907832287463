import React, { Fragment } from 'react';

import styles from './index.module.scss';

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ContactForm from "../../components/ContactForm/ContactForm";
import Head from "../../layout/Head/Head";
import Container from "../../hoc/Container/Container";
import OfficesList from "./components/OfficesList/OfficesList";

import contactDecoration from '../../assets/contact/contact-decoration.png';
import contactDecoration2x from '../../assets/contact/contact-decoration@2x.png';

const ContactPage = props => {

  return (
    <Fragment>
      <Head title="Connatix - Contact Us"/>
      <main className={styles['main-content']}>
        <Container>
          <Breadcrumbs {...props} hasBackground={false}/>
          <section className={styles['form-section']}>
            <div className={styles['form-section-info']}>
              <div className={styles['form-section-info__title-decorator']}/>
              <h1 className={styles['form-section-info__title']}>Get in touch.</h1>
              <p className={styles['form-section-info__description']}>
                Ready to level-up your video game?  Have questions for our team? Send us a note and we'll get back to you soon!
              </p>
            </div>
            <div className={styles['contact-form-container']}>
              <ContactForm formID='1004' onContactPage={true}/>
            </div>

            <img srcSet={`${contactDecoration} 1x, ${contactDecoration2x} 2x`} alt="" className={styles['form-section__decoration']}/>
          </section>
        </Container>
        <OfficesList/>
      </main>
    </Fragment>
  )
};

export default ContactPage;