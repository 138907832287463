import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ContactForm.scss';

class ContactForm extends Component {

  setPricingPlanScript;
  componentDidMount() {
    const marketoInitScript = document.createElement('script');
    marketoInitScript.innerText = (`MktoForms2.loadForm("//app-ab33.marketo.com", "733-VZA-777", ${this.props.formID});`);
    document.body.append(marketoInitScript);
  }


  render() {

    return (
      <div className={`contact-form-container ${this.props.onContactPage ? 'contact-form--contact-page' : ''}`}>
        <img src={this.props.formDecoration} alt="" className="contact-form-decoration" />
        <div className={'contact-form'}>
          <form id={`mktoForm_${this.props.formID}`} className="marketo-form" />
        </div>
      </div>
    );
  }
}

ContactForm.propTypes = {
  formID: PropTypes.string.isRequired, // The ID of the Marketo Form
  onContactPage: PropTypes.bool,
  pricingPlan: PropTypes.string
};

export default ContactForm;