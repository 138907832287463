import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

import styles from './index.module.scss';
import Head from "../../../layout/Head/Head";

import titleDecoration from '../../../assets/wave-line-horizontal.svg';
import Header from "../../../hoc/Header/Header";
import OneColHeaderContent from "../../../components/OneColHeaderContent/OneColHeaderContent";
import headerTitleDecoration from '../../../assets/wave-line-vertical.svg';
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import LeadershipList from "./components/LeadershipList/LeadershipList";
import headerBkg from '../../../assets/leadership/header-background.png';

const LeadershipPage = props => {
  const [owners, setOwners] = useState([]);
  const [leadership, setLeadership] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/leadership`)
      .then(response => {
        setOwners(response.data.entries.slice(0, 2));
        setLeadership(response.data.entries.slice(2))
      })
      .catch(error => console.log('Get Leadership: error:: ', error));
  }, []);
  return (
    <Fragment>
      <Head title="Connatix - Leadership" />
      <Header hasMask={true}
              backgroundImage={headerBkg} backgroundPosition='top'>
        <Breadcrumbs {...props}/>
        <OneColHeaderContent
          title="Meet our team of innovators, leaders, and industry experts."
          titleDecoration={headerTitleDecoration}
          description="To build great things, it starts with the people. We’ve hired a stellar team of innovators,
                         industry leaders, and creative thinkers."
        />
      </Header>

      <main className={styles['main-content']}>
        <section className={styles['leadership']}>
          <SectionTitle title='Leadership'
                        textCentered={true}
                        textColor='rgba(0,0,0,.87)'
                        textBackground='#f6f9fd'
                        textDecoration={titleDecoration}/>
          <LeadershipList leadershipMembers={leadership} leadershipOwners={owners}/>
        </section>
      </main>
    </Fragment>
  );
};

export default LeadershipPage;