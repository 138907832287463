import React, { useState, useEffect } from 'react';
import Head from '../../../layout/Head/Head';
import axios from "axios";

import ContactFormSection from "../../../components/ContactFormSection/ContactFormSection";
import TwoColumnsTextSection from "../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";

import section1Image from '../../../assets/automated-content/automatedcontent_section1.png';
import section1DecorativeImage from '../../../assets/automated-content/automatedcontent_decorative_section1.png';

import section2Image from '../../../assets/automated-content/automatedcontent_section2.png';
import section2DecorativeImage from '../../../assets/automated-content/automatedcontent_decorative_section2.png';

import section3Image from '../../../assets/automated-content/automatedcontent_section3.png';
import section3DecorativeImage from '../../../assets/automated-content/automatedcontent_decorative_section3.png';
import formDecoration from '../../../assets/contact/contact-form-decoration-purple.png';
import Carousel from '../../../hoc/Carousel/Carousel';
import QuotesCarousel from '../../../components/QuotesCarousel/QuotesCarousel';
import * as styles from './index.module.scss';

import AutomatedContentHeader from './components/AutomatedContentHeader/AutomatedContentHeader';

const section1DecorativeImageId = 'section1__decorative';
const section1DecorativeImageStyle = `
  #${section1DecorativeImageId} {
    max-width: 160px;
    min-width: 80px;
    width: 15%;
    bottom: -85px;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section1DecorativeImageId} {
      bottom: -105px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section1DecorativeImageId} {
      width: 30%;
      bottom: -30%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section1DecorativeImageId} {
      right: -10%;
    }
  }
`;

const section2DecorativeImageId = 'section2__decorative';
const section2DecorativeImageStyle = `
  #${section2DecorativeImageId} {
    max-width: 180px;
    min-width: 95px;
    width: 10%;
    bottom: -95px;
    left: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section2DecorativeImageId} {
      width: 15%;
      bottom: -120px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section2DecorativeImageId} {
      width: 30%;
      bottom: -30%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section2DecorativeImageId} {
      left: -15%;
    }
  }
`;

const section3DecorativeImageId = 'section4__decorative';
const section3DecorativeImageStyle = `
  #${section3DecorativeImageId} {
    max-width: 130px;
    min-width: 70px;
    width: 15%;
    bottom: -90px;
    right: 1%;
  }

  @media only screen and (min-width: 48rem) {
    #${section3DecorativeImageId} {
      bottom: -95px;
    }
  }

  @media only screen and (min-width: 62rem) {
    #${section3DecorativeImageId} {
      width: 25%;
      bottom: -40%;
    }
  }

  @media only screen and (min-width: 75rem) {
    #${section3DecorativeImageId} {
      right: -10%;
    }
  }
`;

const AutomatedContentPage = props => {
  const sectionOptions = {
    imageBackgroundMargin: "8% 0% 8% -4%",
    backgroundColor: "#FFFFFF",
    direction: 'row'
  }
  const [automatedContentCarousel, setAutomatedContentCarousel] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quotesSectionTitle, setQuotesSectionTitle] = useState();
  const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/ACGPageFeaturedItemsCarousel`)
      .then(response => {
        setAutomatedContentCarousel(response.data.entries ? response.data.entries : [])
      });

    axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/acgQuotesCarousel`)
      .then(response => {
        setQuotes(response.data.entries ? response.data.entries : [])
        if (response.data.entries) {
          //Set Quote Section Background Color
          let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
          setQuotesSectionBgColor(entryWBgColor.backgroundColor);
          //Set Quote Section Title
          let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
          setQuotesSectionTitle(entryWTitle.sectionTitle);
        }

      })
      .catch(error => console.log('Quotes Carousel Error: ', error));
  }, []);
  return (
    <div className={styles['overflow-hidden-container']}>
      <Head title="Connatix - Video Creation" />
      <AutomatedContentHeader {...props} />

      <main>
        {/* Section 1 */}
        <section>
          <TwoColumnsTextSection
            image={section1Image}
            imageBackgroundMargin="4% -8%"
            imageWidth="120%"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            decorativeImage={section1DecorativeImage}
            decorativeImageId={section1DecorativeImageId}
            decorativeImageStyle={section1DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Unlock endless possibilities with the video creation tool"        
            bulletList={["Create bespoke videos with 30+ customizable templates", "Access 200+ million pre-licensed images, videos, b-roll and music"]}            
          />
        </section>

        {/* Section 2 */}
        <section>
          <TwoColumnsTextSection
            image={section2Image}
            imageBackgroundMargin="6% -4%"
            direction="row"
            backgroundColor="#FFFFFF"
            decorativeImage={section2DecorativeImage}
            decorativeImageId={section2DecorativeImageId}
            decorativeImageStyle={section2DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Easily produce more video-first stories"
            info="Tap into a full suite of integrated video creation features"
            decoratedBulletList={[
              {
                title: "Discovery videos",
                desc: "convert articles and videos into a captivating reel"
              },{

              title: "Dynamic videos",
              desc: "update automatically with timely information and stats"
            },
            {
              title: "Companion videos",
              desc: "create a complementary video for every article"
            },
            ]}
          />      
              
        </section>

        {/* Section 3  */}
        <section>
          <TwoColumnsTextSection
            image={section3Image}
        
            imageBackgroundMargin="4% 6% 2% -4%"
            imageWidth="108%"
            // imageMaxWidth="540px"
            // sectionWidth="100%"
            // sectionMaxWidth="590px"
            direction="row-reverse"
            backgroundColor="#EFF3F5"
            decorativeImage={section3DecorativeImage}
            decorativeImageId={section3DecorativeImageId}
            decorativeImageStyle={section3DecorativeImageStyle}
            hasPaddingBottom={true}
            descriptionDecorationColor="#FFCC00"
            description="Syndicate additional video content"
            info="Access videos from top publishers to engage readers with more content and amplify monetization opportunities."
            // buttonText="LEARN MORE"
            // buttonLink="/video-analytics"
          />
        </section>

        {/* Section 4 */}
        <Carousel carouselItems={automatedContentCarousel} sectionOptions={sectionOptions}></Carousel>

        {/*Quotes */}
        <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
          <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
        </section>

        {/* Contact form */}
        <ContactFormSection id="automated-content-contact"
          title="Ready to bring automation to your editorial strategy?"
          titleDecorationColor='#F2DC4F'
          description="Reach out to learn more."
          formID="1283"
          formDecoration={formDecoration}
        />
      </main>
    </div>
  )
};

export default AutomatedContentPage;