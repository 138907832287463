import React, { Component } from 'react';
import * as styles from './WhoWeAreSection.module.scss';
import Container from "../../../../../hoc/Container/Container";
//import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';


class WhoWeAreSection extends Component {
  componentDidMount() {
    if (this.props.isVideo) {
      const script = document.createElement("script");
      script.innerText = (`cnx.cmd.push(function() {
        cnx({
          playerId: 'a5173d6b-4fd1-4cd6-b731-ef156b463ff5',      
          mediaId: '89d3856b-4c93-4a59-89eb-4ed40bd62266'
        }).render('ae4a21b780fe4433ac66e54ffd631d3a');
      });`);

      const playerContainer = document.getElementById('ae4a21b780fe4433ac66e54ffd631d3a');
      playerContainer.append(script);
    }
  }

  render() {

    return (
      <section className={styles['page-section']} style={{backgroundColor: this.props.backgroundColor}}>
        <Container>
          <div className={styles['page-section-container']} style={{flexDirection: this.props.reverse ? 'row-reverse' : 'row'}}>
            {this.props.isVideo ?
              <div id="ae4a21b780fe4433ac66e54ffd631d3a"></div> : null
            }
            <img className={styles['page-section__img']} srcSet={`${this.props.image} 1x, ${this.props.image2x} 2x`} alt=""/>
            <div className={styles['page-section__content']}>
              <h2 className={styles['page-section__title']}>{this.props.title}</h2>
              <p className={styles['page-section__description']}>{this.props.description}</p>
              {this.props.hasButton ?
                <Link to={this.props.redirectTo} className={styles['page-section__link']}>{this.props.buttonText}</Link> : undefined
              }
            </div>
          </div>
        </Container>
      </section>
    );
  }
} 

export default WhoWeAreSection;