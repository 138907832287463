import React, { Fragment } from 'react';

import Head from "../../../layout/Head/Head";

import Header from "../../../hoc/Header/Header";
import OneColHeaderContent from "../../../components/OneColHeaderContent/OneColHeaderContent";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import styles from './index.module.scss';
import WhoWeAreSection from "./components/WhoWeAreSection/WhoWeAreSection";

import headerBkg from '../../../assets/who-we-are/header.png';
import imgOne from '../../../assets/who-we-are/whoweare_section2_onelayer.png';
import imgOne2x from '../../../assets/who-we-are/whoweare_section2_onelayer@2x.png';
import imgTwo from '../../../assets/who-we-are/section-two.png';
import imgTwo2x from '../../../assets/who-we-are/section-two@2x.png';
import imgThree from '../../../assets/who-we-are/section-three.png';
import imgThree2x from '../../../assets/who-we-are/section-three@2x.png';

const WhoWeArePage = props => {

  return (
    <Fragment>
      <Head title="Connatix - Who We Are"/>
      <Header
        backgroundImage={headerBkg}
        hasMask={true}
      >
        <Breadcrumbs {...props} currentPositionText="Who We Are"/>
        <OneColHeaderContent
          title="Going beyond the player"
          description="Our mission is to help publishers grow their business by delivering successful video experiences."
          buttonText="Join our team"
          redirectPath='/careers'
        />
      </Header>
      <main className={styles['main-content']}>
        <WhoWeAreSection
          title="Meet the people behind the product."
          description="We’re are a group of innovators, disruptors, techies, and storytellers.
                    We also enjoy a hearty game of FIFA."
          isVideo={true}
          image={imgOne}
          image2x={imgOne2x}
          backgroundColor="#FFFFFF"
          hasButton={true}
          redirectTo="/careers#meet-the-team"
          buttonText="Meet the team"
        />
        <WhoWeAreSection
          title="Career opportunities."
          description=" We’re growing quickly! We’re looking for talented, motivated individuals who want to deep dive
                      into the dynamic world of adtech. Interested in joining our team?"
          image={imgTwo}
          image2x={imgTwo2x}
          backgroundColor="#F8F7F7"
          reverse={true}
          hasButton={true}
          redirectTo="/careers#open-positions-list"
          buttonText="View Open Positions"
        />
        <WhoWeAreSection
          title="Behind the scenes with David Kashak"
          description="We sat down with our CEO, David Kashak, to learn more about his vision for Connatix, and mission
        to help publishers deliver successful video experiences. Check it out!"
          image={imgThree}
          image2x={imgThree2x}
          backgroundColor="#FFFFFF"
          hasButton={true}
          redirectTo="blog/behind-the-scenes-with-david-kashak"
          buttonText="Read More"
        />
      </main>
    </Fragment>
  )
};

export default WhoWeArePage;