import React, { useEffect, useRef, useState } from "react";
import lottie from 'lottie-web';
import TwoColumnsTextSection from "../../../../hoc/TwoColumnsTextSection/TwoColumnsTextSection";
import ContextualHeader from "../components/ContextualHeader/ContextualHeader";
import LogosGallery from "../../../home/components/LogosGallery/LogosGallery";
import axios from "axios";
import * as styles from './ContextualPage.module.scss';
import QuotesCarousel from "../../../../components/QuotesCarousel/QuotesCarousel";
import ContactFormSection from "../../../../components/ContactFormSection/ContactFormSection";
import formDecoration from '../../../../assets/contact/contact-form-decoration-black.png';
import Head from "../../../../layout/Head/Head";

import data1 from "../../../../assets/lottie-animations/contextual/data1.json";
import data2 from "../../../../assets/lottie-animations/contextual/data2.json";
import data3 from "../../../../assets/lottie-animations/contextual/data3.json";
import data4 from "../../../../assets/lottie-animations/contextual/data4.json";



const ContextualPage = () => {
    //HEADER DATA
    const [headerTitle, setHeaderTitle] = useState('');
    const [headerDesc, setHeaderDesc] = useState('');
    //STATIC SECTION DATA
    const [staticSectionData, setStaticSectionData] = useState([]);
    //LOGO DATA
    const [logosGallery, setLogosGallery] = useState([]);
    const [logosTitle, setLogosTitle] = useState('');
    const [logosDesc, setLogosDesc] = useState('');
    //ANIMATION DATA
    const [animationData, setAnimationData] = useState([]);
    //METRICS DATA
    const [metricsData, setMetricsData] = useState([]);
    //QUOTES DATA
    const [quotes, setQuotes] = useState([]);
    const [quotesSectionTitle, setQuotesSectionTitle] = useState();
    const [quotesSectionBgColor, setQuotesSectionBgColor] = useState();
    // const [canAddAnimation, setCanAddAnimation] = useState(false);

    const animation1 = useRef(null);
    const animation2 = useRef(null);
    const animation3 = useRef(null);
    const animation4 = useRef(null);

    


    useEffect(() => {

        //GET HEADER DATA
        axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/contextualPageHeader`)
            .then(response => {
                setHeaderTitle(response.data.title ? response.data.title : '');
                setHeaderDesc(response.data.description ? response.data.description : '');
            });

        //GET STATIC SECTION DATA
        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/contextualPageStaticSections`)
            .then(response => {
                setStaticSectionData(response.data.entries ? response.data.entries : []);
            })
            .catch(error => console.log('Static Section Error: ', error));


        //GET LOGO DATA
        axios.get(`${process.env.REACT_APP_API_URL}/api/singletons/get/contextualPageLogosGallery`)
            .then(response => {
                setLogosGallery(response.data.logosGallery ? response.data.logosGallery : [])
                setLogosTitle(response.data.title ? response.data.title : '');
                setLogosDesc(response.data.description ? response.data.description : '');
            });

        //GET ANIMATION SECTION DATA

        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/contextualPageAnimationSection`)
            .then(response => {
                setAnimationData(response.data.entries ? response.data.entries : []);
            })
            .catch(error => console.log('Static Section Error: ', error));


        //GET METRICS DATA
        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/contextualPageMetrics`)
            .then(response => {
                setMetricsData(response.data.entries ? response.data.entries : []);
            }).catch(error => console.log('Metrics Error: ', error));

        //GET CAROUSEL DATA
        axios.get(`${process.env.REACT_APP_API_URL}/api/collections/get/contextualPageQuotesCarousel`)
            .then(response => {
                setQuotes(response.data.entries ? response.data.entries : [])
                if (response.data.entries) {
                    //Set Quote Section Background Color
                    let entryWBgColor = response.data.entries.find(entry => entry.backgroundColor);
                    setQuotesSectionBgColor(entryWBgColor.backgroundColor);
                    //Set Quote Section Title
                    let entryWTitle = response.data.entries.find(entry => entry.sectionTitle);
                    setQuotesSectionTitle(entryWTitle.sectionTitle);
                }

            })
            .catch(error => console.log('Quotes Carousel Error: ', error));




    }, []);

    useEffect(() => {
        animation1.current = lottie.loadAnimation({
            container: document.getElementById('animation1'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: data1
        });

        animation2.current = lottie.loadAnimation({
            container: document.getElementById('animation2'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: data2
        });

        animation3.current = lottie.loadAnimation({
            container: document.getElementById('animation3'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: data3
        });

        animation4.current = lottie.loadAnimation({
            container: document.getElementById('animation4'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: data4
        });



    }, []);


    return (
        <div>
            <Head title="Connatix - Deep Contextual"/>
            <ContextualHeader
                headerTitle={headerTitle}
                headerDesc={headerDesc}
            />

            <section>
                <TwoColumnsTextSection
                    image={staticSectionData.length > 0 ? `${process.env.REACT_APP_API_URL}${staticSectionData[0].image.path}` : ''}
                    imageBackgroundMargin="4% 0 4% -4%"
                    imageWidth="108%"
                    direction="row-reverse"
                    backgroundColor="#FFFFFF"
                    descriptionDecorationColor="#FFCC00"
                    hasPaddingBottom={true}
                    description={staticSectionData.length > 0 ? staticSectionData[0].title : ''}
                    info={staticSectionData.length > 0 ? staticSectionData[0].description : ''}
                    secondInfo={staticSectionData.length > 0 ? staticSectionData[0].secondDescription : ''}
                />
            </section>

            <section className={styles['animation-section']}>
                <div className={styles['title-decoration']}></div>
                <h2>How Deep Contextual works</h2>
             

                <div className={`${styles['animation-section__container']} ${styles['first']}`}>
                    <div className={styles['text-container']}>
                        <span><div className={styles['circle']}></div> <span>1/4</span></span>
                        <h3>{animationData.length > 0 ? animationData[0].title : ''}</h3>
                        <p>{animationData.length > 0 ? animationData[0].body : ''}
                        </p>
                    </div>
                    <div id="animation1" className={styles['animation-canvas']}></div>
                </div>

                <div className={`${styles['animation-section__container']} ${styles['second']}`}>
                    <div className={styles['text-container']}>
                        <span><div className={styles['circle']}></div><span>2/4</span></span>
                        <h3>{animationData.length > 0 ? animationData[1].title : ''}</h3>
                        <p>{animationData.length > 0 ? animationData[1].body : ''} </p>
                    </div>
                    <div id="animation2" className={`${styles['animation-canvas']} ${styles['animation2']} `}>
                        <span>Knowledge graph in action</span>
                    </div>
                </div>


                <div className={`${styles['animation-section__container']} ${styles['third']}`}>
                    <div className={styles['text-container']}>
                        <span><div className={styles['circle']}></div><span>3/4</span></span>
                        <h3>{animationData.length > 0 ? animationData[2].title : ''}</h3>
                        <p>{animationData.length > 0 ? animationData[2].body : ''}
                        </p>
                    </div>
                    <div id="animation3" className={styles['animation-canvas']}></div>
                </div>

                <div className={`${styles['animation-section__container']} ${styles['fourth']}`}>
                    <div className={styles['text-container']}>
                        <span><div className={styles['circle']}></div><span>4/4</span></span>
                        <h3>{animationData.length > 0 ? animationData[3].title : ''}</h3>
                        <p>{animationData.length > 0 ? animationData[3].body : ''}
                        </p>
                    </div>
                    <div id="animation4" className={styles['animation-canvas']}></div>
                </div>

            </section>



            {/* METRICS SECTION */}
            <div className={styles['metrics-section']}>
                <div>
                    <div className={styles['title-decoration']}></div>
                    <h2>Deep Contextual drives results</h2>
                </div>

                <div className={styles['metrics-container']}>
                    {
                        metricsData.map((metric, index) => (
                            <div key={index} className={styles['metric']}>
                                <img src={`${process.env.REACT_APP_API_URL}/${metric.image.path}`} alt="metric"/>
                                <h3>{metric.stat}</h3>
                                <span>{metric.description}</span>

                            </div>

                        )

                        )
                    }
                </div>
                <span className={styles['footnote']}>*Campaigns with Deep Contextual targeting applied vs non-contextually targeted campaigns.</span> 


            </div>


            {/* LOGOS SECTION */}

            <div className={styles['logos-wrapper']}>
                <LogosGallery logosGallery={logosGallery} logosTitle={logosTitle} logosDescription={logosDesc} />
            </div>



            {/*QUOTES SECTION */}
            <section className={`quotes-section ${quotesSectionBgColor === 'white' ? 'bg-white' : 'bg-gray'}`}>
                <QuotesCarousel quotes={quotes} quotesSectionTitle={quotesSectionTitle} />
            </section>

            <ContactFormSection id="contextual-contact"
                title="Ready to level up your video advertising?"
                titleDecorationColor='#FFCC00'
                description="Reach out to get started."
                formID="1546"
                formDecoration={formDecoration}

            />

        </div>
    );
};

export default ContextualPage;