import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/connatix-logo.svg'

import styles from './Navigation.module.scss';
import './hamburgers/hamburgers.scss'
import MobileMenu from './components/MobileMenu/MobileMenu';
import DesktopMenu from "./components/DesktopMenu/DesktopMenu";

const navigationLinks = [
  {
    category: 'Publishers',
    links: [
      {text: 'Video Delivery', path: '/video-delivery'},
      {text: 'Video Monetization', path: '/video-monetization'},
      {text: 'Video Analytics', path: '/video-analytics'},
      {text: 'Video Creation', path: '/video-creation'}
    ]
  },
  {
    category: 'Advertisers',
 
    links: [
      {text: 'Advertisers', path: '/advertisers'},
      {text: 'Deep Contextual', path: '/deep-contextual'}
    ]
  },
  {
    category: 'News & Views',
    links: [
      {text: 'Blog', path: '/blog'},
      {text: 'Press', path: '/press'},
    ]
  },
  {
    category: 'About Us',
    links: [
      {text: 'Who we are', path: '/who-we-are'},
      {text: 'Leadership', path: '/leadership'},
      {text: 'Careers', path: '/careers'},
      {text: 'General Privacy', path: '/privacy-policy'},
      {text: 'Job Applicant Privacy', path: '/job-applicant-privacy-policy'},
      {text: 'Copyright', path: '/copyright'},
      {text: 'Contact us', path: '/contact'}
    ]
  },
  {
    category: 'Pricing',
    categoryPath: '/pricing',
    links: [
      {text: 'Pricing', path: '/pricing'}
    ]
  },
];


const Navigation = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  // Classes for Hamburger Menu Icon
  const menuToggleClasses = [
    'hamburger',
    'hamburger--slider',
    styles['navbar__menu-toggle']
  ];

  // Toggle Hamburger Menu Icon
  if (isMobileNavOpen) {
    menuToggleClasses.push('is-active');
  }

  const toggleMobileNav = () => {
    isMobileNavOpen ? setIsMobileNavOpen(false) : setIsMobileNavOpen(true);
    const bodyElem = document.getElementsByTagName("body");
    const htmlElem = document.getElementsByTagName('html');
    htmlElem[0].classList.toggle('body--overflow');
    bodyElem[0].classList.toggle('body--overflow');
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
    const bodyElem = document.getElementsByTagName("body");
    const htmlElem = document.getElementsByTagName('html');
    htmlElem[0].classList.remove('body--overflow');
    bodyElem[0].classList.remove('body--overflow');
  };

  return (
    <nav className={styles['navbar']}>
      <div className={styles['navbar-logo-container']}>
        <div className={menuToggleClasses.join(' ')} onClick={toggleMobileNav}>
          <div className="hamburger-box">
            <div className="hamburger-inner"/>
          </div>
        </div>
        <Link to="/" onClick={closeMobileNav} className={styles['logo-container']}>
          <img src={logo} alt="Connatix"/>
        </Link>
      </div>

      {isMobileNavOpen ?
        <MobileMenu navigationLinks={navigationLinks} closeMobileNav={closeMobileNav}/>
        :
        undefined
      }
      <DesktopMenu navigationLinks={navigationLinks}/>
      <div className={styles['navbar-user-actions']}>
        <a href="https://portal.connatix.com/login"
           rel="noreferrer noopener"
           target="_blank"
           className={styles['navbar__login-btn']}>Login</a>
        <Link to="/contact" className={styles['navbar__demo-btn']}>REQUEST A DEMO</Link>
      </div>
    </nav>
  )
};

export default Navigation;